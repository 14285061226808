* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Jost", sans-serif;
}

.navbar {
  width: 100% !important;
  background-color: #000 !important;
  color: white;
}

.logo {
  width: 230px !important;
  /* margin-left: 50px; */
}
.logo > img {
  width: 100%;
}

.home-container {
  background-color: #000;
  color: white;
  width: 100%;
  height: 450px;
}

.front-img {
  width: 40%;
  display: flex;
  justify-content: start;
}

.front-content {
  width: 60%;
  padding: 90px 20px;
}

.head-txt {
  font-size: 35px;
}
.head-txt-1 {
  font-size: 40px;
  color: #943de1;
}
.btn-contact {
  background-color: #bd83f4 !important;
  color: white !important;
  border: none !important;
  padding: 8px 120px !important;
  border-radius: 20px !important;
  margin-top: 30px !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 578px) {
  .home-container {
    height: auto !important;
  }
  .logo {
    width: 110px !important;
  }
  .btn-contact {
    padding: 5px 80px !important;
    margin-top: 20px;
  }
  .mobile-reverse {
    flex-direction: column-reverse !important;
  }
  .front-img {
    width: 100% !important;
  }
  .front-content {
    width: 100% !important;
    padding: 50px 10px !important;
  }
  .head-txt {
    font-size: 16px !important;
  }
  .head-txt-1 {
    font-size: 32px !important;
    text-align: center;
  }
}

.section-container {
  background-color: #000;
  width: 100%;
  height: 100vh;
}
.section-1 {
  width: 100%;
  position: relative;
}

.section-combine {
  color: white;
  width: 100%;
  position: absolute;
  justify-content: space-evenly;
  bottom: 3%;
}
.box-1 {
  background-color: #000;
  color: white;
  width: 270px;
  padding: 15px 20px;
  height: 120px;
  border-radius: 10px;
}
.box-2 {
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  width: 360px;
  color: #000;
}
.box-2 > h6 {
  font-size: 16px;
  font-weight: 600;
}
.box-2 > p {
  font-size: 14px;
  font-weight: 400;
}

.about-combine {
  gap: 35px;
}

@media only screen and (max-width: 578px) {
  .section-1 {
    width: 100%;
  }
  .section-combine {
    width: 100%;
    position: static !important;
    background-color: #000;
    justify-content: space-between !important;
  }
  .box-1 {
    margin-top: 0px !important;
  }
  .box-2 {
    width: 100%;
  }
  .section-container {
    height: auto !important;
  }
  .about-combine {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
  }
}

.section-2 {
  background-image: url("../public/assets/Group\ 33.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 500px;
  width: 100%;
}

.section-3 {
  background-image: url("../public/assets/Group\ 36.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
}
.head-txt-2,
.head-txt-2-1 {
  color: white;
  font-size: 36px;
  font-weight: 800;
}
.txt-2 {
  color: #943de1;
  font-size: 16px;
  font-weight: 500;
}
.txt-3 {
  color: #000;
}
.content-1 {
  width: 51%;
}
.txt-one {
  font-size: 25px;
  font-weight: 600;
}
.content-2 {
  width: 80%;
  font-weight: 600;
}
.content-3 {
  width: 60%;
}

.combine-content {
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 60px;
}
.combine-content-1 {
  padding: 10px 70px;
  gap: 25px;
  margin-top: 130px;
}
.btn {
  background-color: #ecd7c4 !important;
  color: #000 !important;
  border: none;
  border-radius: 20px !important;
  font-weight: 600 !important;
  font-size: 16px;
  padding: 5px 25px;
}
.btns {
  background-color: #ecd7c4 !important;
  color: #000 !important;
  border: none;
  border-radius: 20px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 5px 25px !important;
}
.box {
  background-color: #202020;
  color: white;
  width: 260px;
  height: 320px;
  text-align: center;
  padding: 30px 8px 5px 8px;
  border-radius: 20px;
}
.boxs {
  background-color: #2c2b31;
  color: white;
  width: 270px;
  height: 360px;
  text-align: center;
  position: absolute;
  right: 75%;
  top: 23%;
  padding: 30px 8px 0px 8px;
  border-radius: 20px;
}
.box-combine {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 10px;
}
.btn-3-1 {
  display: flex;
  height: 90px;
  align-items: end;
  justify-content: center;
}

.btn-2-1 {
  display: flex;
  height: 70px;
  align-items: end;
  justify-content: center;
}
.txt-4 {
  font-size: 25px;
  font-weight: 400;
}
.txt-5 {
  color: #97969b;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.box-1 {
  margin-top: 20px;
}
.btn-1 {
  display: flex;
  height: 120px;
  align-items: end;
  justify-content: center;
}
.btn-5 {
  display: flex;
  height: 70px;
  align-items: end;
  justify-content: center;
}
.btn-6 {
  display: flex;
  height: 60px;
  align-items: end;
  justify-content: center;
}
.btn-7 {
  display: flex;
  height: 60px;
  align-items: end;
  justify-content: center;
}
.btn-4 {
  display: flex;
  height: 50px;
  align-items: end;
  justify-content: center;
}
.btn-2 {
  display: flex;
  height: 100px;
  align-items: end;
  justify-content: center;
}
.btn-3 {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
}
.head-txt-3 {
  font-size: 34px;
  color: white;
  font-weight: 500;
  text-align: center;
}
.head-txt-3-2 {
  font-size: 34px;
  color: white;
  font-weight: 500;
  /* margin-top: 300px; */
  text-align: center;
}
.head-txt-3-1 {
  color: #943de1;
}
.section-container-1 {
  width: 100%;
  height: auto;
  background-color: #000;
}
.section-2-combines {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between !important;
  gap: 180px;
}

.section-3-combines {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between !important;
  gap: 250px;
}
.img-content {
  padding: 0px 20px 10px 70px;
  display: flex;
  align-items: end;
  width: 100%;
  justify-content: space-evenly !important;
}
.section-b {
  padding-bottom: 40px;
}
.section-2-row {
  display: flex;
  padding: 20px 20px 10px 70px;
  gap: 20px;
  width: 100%;
  position: relative;
}
.section-4 {
  width: 100%;
  background-color: #000;
  height: auto;
  padding: 50px 70px;
}

.f-txt-1 {
  font-size: 40px;
  font-weight: 500;
  color: white;
}

.section {
  background-color: #000;
  padding: 30px 0px;
}

.f-txt-2 {
  color: #943de1;
  font-size: 40px;
}
.f-txt-3 {
  color: white;
  font-weight: 600;
  font-size: 40px;
}

.labels {
  color: white !important;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined {
  background-color: #000 !important;
  color:white !important;
  border: 3px solid #fff;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-input {
  padding: 6px 11px !important;
  border-radius: none !important;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-input,
.form-control {
  border-radius: none !important;
}

.form-control {
  background-color: #000 !important;
  color:white !important;
  border-radius: none !important;
  border: 3px solid white;
}

.btn-contact-3 {
  background-color: #bd83f4 !important;
  color: white !important;
  font-weight: 600;
  font-size: 20px;
  border-radius: 20px !important;
  padding: 5px 30px !important;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:focus-within,
:where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:hover {
  border: 3px solid #943de1;
}

.form-control:focus {
  box-shadow: none;
  border: 3px solid #943de1;
}

.img-arrow {
  width: 50px;
}

@media screen and (min-width: 380px) and (max-width: 578px) {
  .section-2-row {
    flex-direction: column !important;
    padding: 0px 35px 35px 35px !important;
    width: 100% !important;
    background-color: #000;
    gap: 10px;
  }

  .f-txt-1 {
    font-size: 20px !important;
  }
  .f-txt-2 {
    font-size: 20px !important;
  }

  .f-txt-3{
    font-size: 20px !important;
  }

  .img-arrow{
    width:30px;
  }


  .content-1 {
    width: 100%;
    padding: 0px !important;
  }
  .head-txt-2 {
    margin-bottom: 0px !important;
    /* width: 70%; */
  }
  .head-txt-2-1 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .content-2 {
    width: 100%;
    margin-top: 5px;
    gap: 20px;
    display: flex !important;
  }
  .content-3 {
    width: 100%;
    gap: 20px;
    display: flex !important;
  }
  .btn {
    padding: 2px 5px !important;
    font-size: 10px !important;
  }
  .box-combine {
    flex-direction: column;
  }
  .img-content {
    flex-direction: column-reverse;
    padding: 30px;
    gap: 70px;
  }
  .boxs {
    position: static;
  }
  .btn-1,
  .btn-2,
  .btn-3 {
    align-items: center;
  }
  .btn-3-1 {
    align-items: center;
  }
  .btn-2-1 {
    align-items: center;
  }
  .txt-3,
  .txt-2 {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500 !important;
    width: 60%;
    margin-bottom: 0px !important;
  }
  .section-2 {
    width: 100% !important;
    background-size: contain !important;
    background-position: top;
    padding: 0 !important;
    height: auto !important;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }
  .section-3 {
    width: 100% !important;
    background-size: contain !important;
    background-position: top;
    height: auto !important;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }
  .combine-content {
    margin-top: 20px !important;
    padding: 0px !important;
    gap: 0px;
  }
  .combine-content-1 {
    margin-top: 20px !important;
    padding: 30px;
    gap: 0px;
  }
  .head-txt-2 {
    font-size: 16px;
    font-weight: 800;
  }

  .section-2-combines {
    gap: 20px !important;
    background-color: #000;
    height: auto;
    color: white;
  }
  .section-3-combines {
    gap: 10px !important;
    background-color: #000;
    height: auto;
    color: white;
  }
  .section-2-row {
    top: 150%;
    width: 100% !important;
  }
  .head-txt-3 {
    font-size: 16px;
  }
  .box,
  .boxs {
    width: 100%;
  }
  .section-container-1 {
    height: auto !important;
  }
  .section-4 {
    padding: 20px;
  }
}

@media screen and (min-width: 579px) and (max-width: 880px) {
  .home-container {
    height: auto !important;
  }
  .logo {
    width: 200px !important;
  }
  .btn-contact {
    padding: 5px 80px !important;
    margin-top: 20px;
    font-weight: 600 !important;
  }
  .mobile-reverse {
    flex-direction: column-reverse !important;
  }
  .img-content {
    flex-direction: column-reverse;
    padding: 30px;
    gap: 70px;
  }
  .boxs {
    position: static;
  }
  .front-img {
    width: 100% !important;
  }
  .front-content {
    width: 100% !important;
    padding: 50px 10px !important;
  }
  .head-txt {
    font-size: 28px !important;
  }
  .head-txt-1 {
    font-size: 40px !important;
    text-align: center;
  }
  .txt-4 {
    font-size: 20px;
  }

  .section-1 {
    width: 100%;
  }
  .section-combine {
    width: 100%;
    position: static !important;
    background-color: #000;
    justify-content: space-between !important;
  }
  .box-1 {
    margin-top: 0px !important;
  }
  .box-2 {
    width: 100%;
  }
  .box-2 > p {
    font-size: 16px;
  }
  .box-2 > h6 {
    font-size: 25px;
  }
  .section-container {
    height: auto !important;
  }
  .about-combine {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
  }

  .section-2 {
    width: 100% !important;
    background-size: contain !important;
    background-position: top;
    padding: 0 !important;
    height: auto !important;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }
  .section-3 {
    width: 100% !important;
    background-size: contain !important;
    background-position: top;
    height: auto !important;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }
  .combine-content {
    margin-top: 20px !important;
    padding: 10px 20px 30px 20px !important;
    gap: 0px;
  }
  .combine-content-1 {
    margin-top: 20px !important;
    padding: 130px 20px 120px 20px !important;
    gap: 0px;
  }
  .section-2-row {
    flex-direction: column !important;
    padding: 10px 2px !important;
    width: 100% !important;
    background-color: #000;
    gap: 10px;
  }

  .content-1 {
    width: 100%;
    padding: 0px !important;
  }
  .head-txt-2 {
    margin-bottom: 0px !important;
    /* width: 70%; */
    font-size: 25px !important;
  }
  .head-txt-2-1 {
    width: 100%;
    font-size: 25px;
    margin-bottom: 0px;
  }
  .content-2 {
    width: 100%;
    margin-top: 15px;
    gap: 20px;
    display: flex !important;
  }
  .content-3 {
    width: 100%;
    gap: 20px;
    margin-top: 10px;
    display: flex !important;
  }
  .btn {
    padding: 5px 5px !important;
    font-size: 20px !important;
  }
  .btn-1,
  .btn-2,
  .btn-3 {
    align-items: center;
  }
  .btn-3-1 {
    align-items: center;
  }
  .btn-2-1 {
    align-items: center;
  }
  .txt-3,
  .txt-2 {
    font-size: 20px;
    line-height: 19px;
    font-weight: 500 !important;
    width: 60%;
    margin-bottom: 0px !important;
  }
  .head-txt-2 {
    font-size: 16px;
    font-weight: 800;
  }

  .section-2-combines {
    gap: 20px !important;
    background-color: #000;
    height: auto;
    color: white;
  }
  .section-3-combines {
    gap: 20px !important;
    background-color: #000;
    height: auto;
    color: white;
  }
  .section-2-row {
    top: 150%;
    width: 100% !important;
  }
  .head-txt-3 {
    font-size: 16px;
  }
  .box,
  .boxs {
    width: 100%;
  }
  .section-container-1 {
    height: auto !important;
  }
  .section-4 {
    padding: 20px;
  }
  .txt-5 {
    font-size: 13px;
    line-height: 25px;
  }
  .boxs > .txt-5 {
    margin-top: 25px;
  }
}
